/**
 * メニュー用モジュール。
 * @module app/view/Menu
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/query',
    'dojo/has',
    'dojo/text!./templates/Menu.html',
    'dojo/topic',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/consts/ACL',
    'app/common/consts/FunctionCd',
    'app/config'
    // 以下、変数で受けないモジュール
], function (module, declare, lang, domClass, query, has, template, topic, _WidgetBase, _TemplatedMixin,
    Locator, Router, UserInfo, UserType, ACL, FunctionCd, config) {
    /**
     * リンクの情報を管理するオブジェクト。
     * @typedef LinkInfo
     * @property {string} title リンクのtitle属性（カーソルを合わせたときの表示）
     * @property {string} label リンク文字列
     * @property {string} page ページ識別子、指定時はhrefよりも優先
     * @property {string} href リンク先
     * @property {LinkInfo[]} links リンクの情報の配列
     */

    /**
     * メニュー用オブジェクト
     * @class Menu
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin],
        /** @lends app/view/Menu~Menu# */ {
            // テンプレート文字列
            templateString: template,

            MENU_TYPE_FLAT: 'フラット型メニュー',
            MENU_TYPE_HIERARCHY: '階層型メニュー',

            menuLabelOpen: '▼ メニュー',
            menuLabelClose: '▶︎ メニュー',

            /**
             * リンク情報の配列
             * @type {module: app/view/Menu~LinkInfo[]}
             */
            _links: null,

            href: null,

            // ユーザーの市町村コード
            _municipalityCd: null,

            // LGWAN側アクセスどうか
            _lgwanFlg: false,

            //
            disasterChgDialog: 'app/view/form/DisasterChangerDialog::initMenu',
            disasterUpd: 'app/disaster/view/DisasterAdminPage::initMenu',
            disasterChg: 'app/view/form/DisasterChanger::initMenu',


            // DOM要素準備後に呼ばれる
            buildRendering: function () {
                this.inherited(arguments);

                this.initMenu();

                // 災害名変更がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterChgDialog, lang.hitch(this, function () {
                    this.initMenu();
                }));

                // 災害名管理更新がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterUpd, lang.hitch(this, function () {
                    this.initMenu();
                }));

                // 災害名表示がPubされた際にツリーの情報を更新する
                topic.subscribe(this.disasterChg, lang.hitch(this, function () {
                    this.initMenu();
                }));
            },

            // メニュー作成処理
            initMenu: function () {
                this.inherited(arguments);

                var message = UserInfo.getId() + ' は';
                if (UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                    message = message + '管理ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.SYSMGR_USER) {
                    message = message + '全体管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.SHMGR_USER) {
                    message = message + '市本部管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.XXMGR_USER) {
                    message = message + '所属管理者です。';
                } else if (UserInfo.getRoleCd() === ACL.SHIHONBU_USER) {
                    message = message + '市本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.KUHONBU_USER) {
                    message = message + '区本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.XXHONBU_USER) {
                    message = message + '所属本部ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.IPPAN_USER) {
                    message = message + '一般職員ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.AID_USER) {
                    message = message + '応援職員ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.VPD_USER) {
                    message = message + '自主防災組織ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.WEL_USER) {
                    message = message + '福祉避難所ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.FAC_USER) {
                    message = message + '施設管理者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.SEW_USER) {
                    message = message + '下水処理事業者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.ROA_USER) {
                    message = message + '道路管理事業者ユーザです。';
                } else if (UserInfo.getRoleCd() === ACL.REF_USER) {
                    message = message + '参照権限ユーザです。';
                } else {
                    message = message + '未定義ユーザです。 役割コード:' + UserInfo.getRoleCd();
                }
                console.debug(message);
                console.debug('ACL ADMIN : ' + UserInfo.getAcl().ADMIN);
                console.debug('ACL CHIEF ADMIN: ' + UserInfo.getAcl().CHIEF_ADMIN);

                // ユーザーの市町村コードをセット
                // 非ログイン時にgetMunicipalityCds()[0]でエラーが生じるのを回避
                if (UserInfo.getMunicipalityCd()) {
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }

                // LGWAN側アクセスであるか
                this._lgwanFlg = UserInfo.isLgwan();

                // リンク一覧を生成
                this._links = [];

                if (this.getMenuType() === this.MENU_TYPE_FLAT) {
                    // メニュータイプ：フラット型
                    this._links = this.createMenuTypeFlatLinks();
                } else if (this.getMenuType() === this.MENU_TYPE_HIERARCHY) {
                    // メニュータイプ：階層型
                    this._links = this.createMenuTypeHierarchyLinks();
                } else {
                    // メニュータイプ：階層型
                    this._links = this.createMenuTypeHierarchyLinks();
                    console.warn('メニュータイプが未定義です。 メニュータイプ:' + this.getMenuType());
                }

                this._links.push();
                this.updateLinks();
                this.own(Locator.on('change', lang.hitch(this, 'updateLinks')));
            },

            postCreate: function () {
                this.inherited(arguments);
                // リンクがクリックされたら反応する
                this.on('a:click', lang.hitch(this, function (e) {
                    this.href = e.target.href;
                    var menuLink = false; // ヘッダーに表示されているメニューリンク判定フラグ

                    if (e.target.parentElement.parentElement.id === 'linkList') { // リンクタグの親の親のIDがlinkListの場合
                        // 監視ページはヘッダーにあるがクリックでページ遷移させるためメニューリンクと判定しない
                        if (e.target.text !== '監視ページ') {
                            menuLink = true; // メニューリンクと判定する
                        }

                        // 過去に開いたメニューのエレメントを探す
                        var oldEl = e.target.parentElement.parentElement.querySelector('li.isOpen');

                        if (oldEl) { // 開いたメニューがある場合
                            oldEl.classList.remove('isOpen'); // 閉じる
                        }
                        if (e.target.parentElement !== oldEl) { // 過去に開いたリンクが、今回開いたリンクと異なる場合、
                            e.target.parentElement.classList.add('isOpen'); // メニューを開く
                        }

                    }
                    if (this.href.indexOf(Locator.getHref()) === -1 &&
                        Locator.getHref().indexOf(this.href) === -1) {
                        this.isMenuOpen = false;
                    }
                    if (!Locator.isCurrentPath(this.href)) {
                        // ブラウザーの遷移処理をキャンセル
                        e.preventDefault();
                        if (!menuLink) { // メニューリンクではない場合、遷移先にJump
                            // ページへ遷移
                            Locator.pushState(Locator.getQueryFrom(this.href), true);
                        }
                    }
                    // ページ指定になっていない場合はそのまま通す
                }));

                this.own(Locator.on('change', lang.hitch(this, function () {
                    if (!(this.href === Locator.getHref() ||
                        this.href === Locator.getHref() + '#')) {
                        this.isMenuOpen = false;
                    }
                })));
            },

            updateLinks: function () {

                // 新たなリンク一覧を文字列として構築
                var html = [];
                for (var i = 0; i < this._links.length; i++) {
                    var link = this._links[i];
                    html.push('<li class=""> <a href="');
                    if (link.links) {
                        html.push('#"');
                        if (link.className) {
                            html.push(' class="' + link.className + '"');
                        }
                        html.push('>');
                        html.push(link.label);
                        html.push('</a><ul>');
                        for (var j = 0; j < link.links.length; j++) {
                            var subLink = link.links[j];
                            if (!subLink) {
                                console.log('メニューのリンクが設定されていません。確認してください。');
                                console.log(link.links);
                                continue;
                            }
                            if (subLink.label === '物資要請') {
                                // 物資調達・輸送調整等支援システムをログイン済状態で別画面に開く
                                html.push(this.onBusshiLinkClick());
                            } else {
                                html.push('<li><a href="');
                                if (subLink.external) {
                                    // 外部リンクページの場合
                                    html.push(subLink.url);
                                    // 別タブで開く
                                    html.push('" target="_blank" class="u-external is-inverted"');
                                } else {
                                    html.push(subLink.page ?
                                        ('?' + Router.PAGE_KEY + '=' + subLink.page) : (subLink.href || '#'));
                                }
                                html.push('" title="');
                                html.push(subLink.title);
                                html.push('">');
                                html.push(subLink.label);
                                html.push('</a></li>');
                            }
                        }
                        html.push('</ul></li>');
                    } else {
                        if (link.extlink) {
                            html.push(link.extlink);
                        } else {
                            // 市町ユーザーの場合、メニューから一覧画面へ直接遷移する
                            if (!link.municipalityCd) {
                                html.push(link.page ? ('?' + Router.PAGE_KEY + '=' + link.page) : (link.href || '#'));
                            } else {
                                html.push(link.page ? '?municipalityCd=' + link.municipalityCd +
                                    '&' + Router.PAGE_KEY + '=' + link.page : (link.href || '#'));
                            }
                        }
                        if (link.target) {
                            html.push('" target="');
                            html.push(link.target);
                        }
                        html.push('" title="');
                        html.push(link.title);
                        html.push('">');
                        html.push(link.label);
                        html.push('</a></li>');
                        }
                }
                // リンク一覧の中身を置き換える
                this.linkList.innerHTML = html.join('');
            },

            onBusshiLinkClick: function () {
                var html = [];
                // window.openで \ADISClient\src\busshi.html を呼び出す
                // このHTMLを呼び出すために、\ADISClient\webpack.config.jsにnew HTMLPluginとしてパス定義しておく
                var busshiLoginPage = './busshi.html';
                // var trainingFlg = 1; // 0: 実災害モード、1: 訓練モード
                var trainingFlg = UserInfo.getLoginMode() === '本番' ? '0' : '1';
                var supplyUserId = UserInfo.getSupplyUserId();
                var supplyPassword = UserInfo.getSupplyPassword();

                // パラメータの文字列連結(訓練フラグ・ユーザーID・パスワードをカンマ区切りで連結して送信)
                var url = busshiLoginPage + '?' + trainingFlg + ',' + supplyUserId + ',' + supplyPassword;

                html.push('<li><a href="javascript:void(0);" onClick="');

                // 入力チェック
                html.push('var supplyUserId = \'' + supplyUserId + '\';');
                html.push('var supplyPassword = \'' + supplyPassword + '\';');

                if (supplyUserId === null || supplyPassword === null) {
                    // 戻り先URLの設定
                    if (trainingFlg === '0') {
                        // 実災害モードの場合
                        url = 'https://busshi.bousai-system.go.jp';
                    } else if (trainingFlg === '1') {
                        // 訓練モードの場合
                        url = 'https://busshi.bousai-system.go.jp/training/index.html';
                    }
                }

                // ポップアップブロックを抑止するためブランク画面にURLをセット
                html.push('var win = window.open(\'\',\'_Busshi\');');
                html.push('win.location = \'' + url + '\';');

                // 訓練モードの場合、1回目はログイン不可。2回目でログイン可能となるので再度window.openする
                html.push('if (\'' + trainingFlg + '\'=== \'1\'){');
                html.push('    setTimeout(function(){window.open(\'' + url + '\',\'_Busshi\');},800);');
                html.push('}"');

                html.push('title="物資調達システムを表示します。">物資要請</a></li>');
                return html.join('');
            },

            isMenuOpen: false,

            onMenuLinkClick: function () {
                if (!this.isMenuOpen) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },

            getMenuType: function () {
                var menuType = '';

                if (UserInfo.getRoleCd() === ACL.ADMIN_USER ||
                    UserInfo.getRoleCd() === ACL.HQ_USER) {
                    menuType = this.MENU_TYPE_HIERARCHY;
                } else if (UserInfo.getRoleCd() === ACL.DEPT_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_H_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_USER ||
                    UserInfo.getRoleCd() === ACL.FIRE_USER ||
                    UserInfo.getRoleCd() === ACL.OTHER_USER) {
                    menuType = this.MENU_TYPE_FLAT;
                } else {
                    // 未定義の役割だった場合は階層型メニュータイプを設定
                    menuType = this.MENU_TYPE_HIERARCHY;
                    console.warn('役割コードが未定義です。 役割コード:' + UserInfo.getRoleCd());
                }

                console.debug('メニュータイプ：' + menuType);

                return menuType;
            },

            // サブメニューリンク
            mainMenuLinksLabel: 'メニュー',
            wtrSubLinksLabel: '防災気象情報',
            obsSubLinksLabel: '観測情報等',
            disSubLinksLabel: '外部連携',
            masterSubLinksLabel: '管理',
            convocationSubLinksLabel: '職員招集',
            formDisplaySubLinksLabel: 'デジタルサイネージ',
            otherSubLinksLabel: 'その他',

            // メニューリンク
            monitoringLink: {
                page: 'monitoring', label: '監視ページ',
                title: '監視ページを表示します。'
            },
            disasterLink: {
                page: 'disaster', label: '災害名管理',
                title: '災害名の登録・更新を行います。'
            },
            disasterpreventionLink: {
                page: 'disasterprevention/pref', label: '本部設置管理',
                title: '本部設置の登録・更新を行います。'
            },
            disasterViewLink: {
                page: 'disaster', label: '災害名管理',
                title: '災害名の参照を行います。'
            },
            shelterAdminLink: {
                page: 'shelter/admin', label: '避難所管理',
                title: '避難所情報の管理を行います。'
            },
            shelterAdminViewLink: {
                page: 'shelter/admin', label: '避難所管理',
                title: '避難所情報の参照を行います。'
            },
            shelterListLink: {
                page: 'shelter', label: '避難所管理',
                title: '避難所情報の管理を行います。'
            },
            shelterListViewLink: {
                page: 'shelter', label: '避難所管理',
                title: '避難所情報の参照を行います。'
            },
            evacRecommendLink: {
                page: 'evacrecommend', label: '発令判断支援',
                title: '避難情報発令基準超過画面を表示します。'
            },
            evacorderAdminLink: {
                page: 'evacorder/admin', label: '避難発令',
                title: '避難情報の発令・更新を行います。'
            },
            evacorderAdminViewLink: {
                page: 'evacorder/admin', label: '避難発令',
                title: '避難情報の参照を行います。'
            },
            reportAdminLink: {
                page: 'report/admin', label: '被害情報管理',
                title: '被害情報の登録・更新を行います。'
            },
            reportLink: {
                page: 'report', label: '被害情報管理',
                title: '被害情報の登録・更新を行います。'
            },
            reportAdminViewLink: {
                page: 'report/admin/view', label: '被害情報管理',
                title: '被害情報の参照を行います。'
            },
            scheduledReportPrefLink: {
                page: 'report/sche/pref', label: '定時報告管理',
                title: '定時報告の登録・更新を行います。'
            },
            scheduledReportLink: {
                page: 'report/sche', label: '定時報告管理',
                title: '定時報告の登録・更新を行います。'
            },
            fireReportLink: {
                page: 'fdmaReport', label: '消防庁報告管理',
                title: '消防庁報告の登録・更新を行います。'
            },
            chronologyLink: {
                page: 'chronology', label: 'クロノロジー管理',
                title: 'クロノロジの登録・更新を行います。'
            },
            rescueLink: {
                page: 'rescue', label: '部隊活動情報',
                title: '部隊活動情報の登録・更新を行います。'
            },
            weatherinfoLink: {
                page: 'weatherinfo', label: '気象情報',
                title: '気象情報一覧画面を表示します。'
            },
            earthquakeLink: {
                page: 'earthquake', label: '地震情報',
                title: '地震情報一覧画面を表示します。'
            },
            volcanoLink: {
                page: 'volcano', label: '火山情報',
                title: '火山情報一覧画面を表示します。'
            },
            tsunamiLink: {
                page: 'tsunami', label: '津波情報',
                title: '津波情報一覧画面を表示します。'
            },
            floodforecastLink: {
                page: 'floodforecast', label: '指定河川洪水予報情報',
                title: '指定河川洪水予報情報一覧を表示します。'
            },
            linkcollectionLink: {
                page: 'weatherLinks', label: '気象リンク集',
                title: '気象情報関連のリンク集画面を表示します。'
            },
            observationmapLink: {
                page: 'observationmap', label: '観測概況',
                title: '各観測情報の概況情報を表示します。'
            },
            rainfallobservationLink: {
                page: 'observation/rainfall', label: '雨量情報',
                title: '雨量情報一覧画面を表示します。'
            },
            riverlevelobservationLink: {
                page: 'observation/river', label: '水位情報',
                title: '水位実況情報一覧画面を表示します。'
            },
            crisisManageRiverlevelObsLink: {
                page: 'observation/crisisManage', label: '危機管理型水位計',
                title: '危機管理型水位計情報一覧画面を表示します。'
            },
            provideinformationLink: {
                page: 'sending', label: '情報配信',
                title: '情報配信画面を表示します。'
            },
            emergencyLink: {
                page: 'emergency', label: 'ポータル公開情報管理',
                title: 'ポータル公開情報管理を表示します。'
            },
            specteeLink: {
                page: 'spectee', label: 'SNSサービス情報管理',
                title: 'SNSサービス情報の管理を行います。'
            },
            sip4dCkanLink: {
                page: 'sip4dCkan', label: 'SIP4D-CKAN情報管理',
                title: 'SIP4D-CKAN情報の管理を行います。'
            },
            facilityLink: {
                page: 'facility', label: '施設管理',
                title: '本システムの施設情報を登録・更新・削除します。'
            },
            userLink: {
                page: 'user', label: 'ユーザ管理',
                title: '本システムの利用者情報を登録・更新・削除します。'
            },
            organizationLink: {
                page: 'organization', label: '組織管理',
                title: '組織管理情報を開きます。'
            },
            geofileMasterLink: {
                page: 'folder', label: '地図レイヤー管理',
                title: '地図レイヤー管理'
            },
            geofileLink: {
                page: 'geofile', label: '地図タイル化',
                title: '地図タイル化'
            },
            convocationEmployeeLink: {
                page: 'convocation/employee', label: '職員管理',
                title: '職員の管理を行います。'
            },
            convocationGroupLink: {
                page: 'convocation/group', label: 'グループ一覧',
                title: 'グループ一覧を表示します。'
            },
            convocationMailLink: {
                page: 'convocation/mail', label: '職員招集メール配信管理',
                title: 'メール配信、および、配信メールの管理を行います。'
            },
            convocationAutoMailLink: {
                page: 'convocation/automail', label: '配信条件設定管理',
                title: '配信条件の管理を行います。'
            },
            convocationUserLink: {
                page: 'convocation/user', label: '職員招集管理者管理',
                title: '職員招集管理者情報を登録・更新・削除します。'
            },
            formDisplayLink: {
                page: 'formDisplay', label: 'デジタルサイネージ表示設定管理',
                title: 'デジタルサイネージの設定管理を行います。'
            },
            moveDisplayLink: {
                page: 'moveDisplay', label: 'デジタルサイネージメニュー',
                title: 'デジタルサイネージの画面表示を行います。'
            },
            displayLoginLink: {
                page: 'displayLogin', label: 'デジタルサイネージログイン',
                title: 'デジタルサイネージのログイン画面を表示します。'
            },
            bbsLink: {
                page: 'bbs', label: '掲示板',
                title: '職員向けお知らせ掲示板画面を表示します。'
            },
            simulationLink: {
                page: 'simulation', label: '災害シミュレーション',
                title: '災害シミュレーションを登録・更新・削除します。'
            },
            manualLink: {
                page: 'manual', label: 'マニュアルダウンロード',
                title: 'PDF形式の操作マニュアルがダウンロードできます。'
            },
            dpLinkPageLink: {
                page: 'dpLinks', label: '防災リンク集',
                title: '防災情報関連のリンク集画面を表示します。'
            },
            supplyrequestLink: {
                label: '物資要請',
                title: '物資調達システムを表示します。',
                href: 'https://busshi.bousai-system.go.jp/training/index.html',
                target: '_blank'
            },

            createMenuTypeHierarchyLinks: function () {

                var links = [];

                // 災害対応
                {
                    var mainMenuSubLinks = [];

                    // F09001：災害名管理
                    if (UserInfo.hasAuthz('F09001') &&
                        // 訓練モードの場合、市町村ユーザーで権限があれば表示
                        (UserInfo.isTrainingFlg() ||
                            UserInfo.getUserType() !== UserType.MUNICIPALITY)) {
                        mainMenuSubLinks.push(this.disasterLink);
                    }

                    // F05002：本部設置管理
                    if(UserInfo.hasAuthz('F05002')) {
                        if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                            this.disasterpreventionLink.page = 'disasterprevention/status';
                        }
                        mainMenuSubLinks.push(this.disasterpreventionLink);
                    }

                    // F05006：避難所管理
                    if (UserInfo.hasAuthz('F05006')) {
                        if ((UserInfo.getUserType() === UserType.MUNICIPALITY) &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.shelterListLink.municipalityCd = this._municipalityCd;
                            mainMenuSubLinks.push(this.shelterListLink);
                        } else {
                            mainMenuSubLinks.push(this.shelterAdminLink);
                        }
                    }

                    // F05005：発令判断支援
                    if (UserInfo.hasAuthz(FunctionCd.EVACRECOMMEND)) {
                        mainMenuSubLinks.push(this.evacRecommendLink);
                    }

                    // F05004：避難発令
                    if (UserInfo.hasAuthz('F05004')) {
                        if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                            UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.evacorderAdminLink.municipalityCd = this._municipalityCd;
                            this.evacorderAdminLink.page = 'evacorder';
                        }
                        mainMenuSubLinks.push(this.evacorderAdminLink);
                    }

                    // F05007：被害情報管理
                    if (UserInfo.hasAuthz('F05007')) {
                        // 市町ユーザ
                        if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                            mainMenuSubLinks.push(this.reportLink);
                        } else {
                            mainMenuSubLinks.push(this.reportAdminLink);
                        }
                    }

                    // F05009：定時報告管理
                    if (UserInfo.hasAuthz('F05009')) {
                        // 県ユーザ or 振興局ユーザ
                        if (UserInfo.getUserType() === UserType.PREFECTURE || UserInfo.getUserType() === UserType.REGION) {
                            mainMenuSubLinks.push(this.scheduledReportPrefLink);
                        }
                        else {
                            mainMenuSubLinks.push(this.scheduledReportLink);
                        }
                    }

                    // F05011：消防庁報告管理
                    if (UserInfo.hasAuthz('F05019')) {
                        mainMenuSubLinks.push(this.fireReportLink);
                    }

                    // F05001：クロノロジー管理
                    if (UserInfo.hasAuthz('F05001')) {
                        mainMenuSubLinks.push(this.chronologyLink);
                    }

                    // MEMO:デモ用にメニュー表示制御
                    // F05010：部隊活動情報管理
                    /* if(UserInfo.hasAuthz('F05010')) {
                        mainMenuSubLinks.push(this.rescueLink);
                    } */

                    if (mainMenuSubLinks.length > 0) {
                        links.push({ label: this.mainMenuLinksLabel, links: mainMenuSubLinks, className: 'menuClass' });
                    }
                }

                // F01001：監視ページ
                if(UserInfo.hasAuthz('F01001')) {
                    links.push(this.monitoringLink);
                }

                // 防災気象情報
                {
                    var wtrSubLinks = [];

                    // F03001：気象情報
                    if (UserInfo.hasAuthz('F03001')) {
                        wtrSubLinks.push(this.weatherinfoLink);
                    }

                    // F03002：地震情報
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.earthquakeLink);
                    }

                    // F03002：火山情報
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.volcanoLink);
                    }

                    // F03002：津波情報
                    if (UserInfo.hasAuthz('F03002')) {
                        wtrSubLinks.push(this.tsunamiLink);
                    }

                    // F03002：指定河川洪水予報情報
                    if (UserInfo.hasAuthz('F03001')) {
                        wtrSubLinks.push(this.floodforecastLink);
                    }

                    // F03001：気象リンク集
                    // if(UserInfo.hasAuthz('F03001')) {
                    //     wtrSubLinks.push(this.linkcollectionLink);
                    // }

                    if (wtrSubLinks.length > 0) {
                        links.push({ label: this.wtrSubLinksLabel, links: wtrSubLinks });
                    }
                }

                // 観測情報等
                {
                    var obsSubLinks = [];

                    // F04001 : 観測概況
                    if(UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.observationmapLink);
                    }

                    // F04001：雨量情報
                    if (UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.rainfallobservationLink);
                    }

                    // F04001：水位情報
                    if (UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.riverlevelobservationLink);
                    }

                    // MEMO:デモ用にメニュー表示制御
                    // F04001：危機管理型水位計
                    /* if (UserInfo.hasAuthz('F04001')) {
                        obsSubLinks.push(this.crisisManageRiverlevelObsLink);
                    } */

                    if (obsSubLinks.length > 0) {
                        links.push({ label: this.obsSubLinksLabel, links: obsSubLinks });
                    }
                }

                // 外部連携
                {
                    var disSubLinks = [];

                    // F06001：情報配信
                    if(UserInfo.hasAuthz('F06001')) {
                        disSubLinks.push(this.provideinformationLink);
                    }

                    // F06001：ポータル公開情報管理
                    if(UserInfo.hasAuthz('F06002')) {
                        disSubLinks.push(this.emergencyLink);
                    }

                    // F06008：SNSサービス情報管理
                    if (UserInfo.hasAuthz('F06008') && !this._lgwanFlg) {
                        disSubLinks.push(this.specteeLink);
                    }
                    // MEMO:デモ用にメニュー表示制御
                    // F06001：SIP4D-CKAN情報管理
                    /* if (UserInfo.hasAuthz('F06001') && !this._lgwanFlg) {
                        disSubLinks.push(this.sip4dCkanLink);
                    } */
                    // F06005：物資要請
                    /* if (UserInfo.hasAuthz(FunctionCd.BUSSHI)) {
                        disSubLinks.push(this.supplyrequestLink);
                    } */

                    if (disSubLinks.length > 0) {
                        links.push({ label: this.disSubLinksLabel, links: disSubLinks });
                    }
                }

                // 管理
                {
                    var masterSubLinks = [];

                    // F10004：施設管理
                    if (UserInfo.hasAuthz('F10001')) {
                        masterSubLinks.push(this.facilityLink);
                    }
                    // F10003：ユーザ管理
                    if (UserInfo.hasAuthz('F10003') && UserInfo.getAdminFlg()) {
                        masterSubLinks.push(this.userLink);
                    }
                    // F10004：組織管理
                    if(UserInfo.hasAuthz(FunctionCd.ORGANIZATION)) {
                        masterSubLinks.push(this.organizationLink);
                    }
                    // F09002：地図レイヤー管理（フォルダ管理）
                    if (UserInfo.hasAuthz('F09002')) {
                        masterSubLinks.push(this.geofileMasterLink);
                    }
                    // MEMO:デモ用にメニュー表示制御
                    // F09004：地図タイル化
                    /* if (UserInfo.hasAuthz(FunctionCd.GEOFILE)) {
                        masterSubLinks.push(this.geofileLink);
                    } */

                    if (masterSubLinks.length > 0) {
                        links.push({ label: this.masterSubLinksLabel, links: masterSubLinks });
                    }
                }

                // 職員招集
                {
                    var convocationSubLinks = [];

                    if(UserInfo.hasAuthz('F08001')) {
                        // 職員招集メール配信管理
                        convocationSubLinks.push(this.convocationMailLink);
                    }
                    if(UserInfo.hasAuthz('F08002')) {
                        // 職員管理
                        convocationSubLinks.push(this.convocationEmployeeLink);
                        // グループ一覧
                        convocationSubLinks.push(this.convocationGroupLink);
                        // 配信条件設定管理
                        convocationSubLinks.push(this.convocationAutoMailLink);
                        // 管理ユーザーのみに表示
                        if (UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                            // 職員招集管理者管理
                            convocationSubLinks.push(this.convocationUserLink);
                        }
                    }

                    // MEMO:デモ用にメニュー表示制御
                    /* if(convocationSubLinks.length > 0) {
                        links.push({label: this.convocationSubLinksLabel, links: convocationSubLinks});
                    } */
                }

                // デジタルサイネージ
                {
                    var formDisplaySubLinks = [];

                    if (UserInfo.hasAuthz('F14001')) {
                        // デジタルサイネージ表示設定管理
                        formDisplaySubLinks.push(this.formDisplayLink);
                    }
                    if (UserInfo.hasAuthz('F14002')) {
                        // デジタルサイネージメニュー
                        // formDisplaySubLinks.push(this.moveDisplayLink);
                        // デジタルサイネージログイン
                        formDisplaySubLinks.push(this.displayLoginLink);
                    }

                    // MEMO:デモ用にメニュー表示制御
                    /* if (formDisplaySubLinks.length > 0) {
                        links.push({ label: this.formDisplaySubLinksLabel, links: formDisplaySubLinks });
                    } */
                }

                // その他
                {
                    var otherSubLinks = [];

                    // 掲示板画面
                    if(UserInfo.hasAuthz('F07001')||UserInfo.hasAuthz('F07002')) {
                        otherSubLinks.push(this.bbsLink);
                    }

                    // 災害シミュレーション（訓練モードにのみ表示）
                    if(UserInfo.hasAuthz('F12001') && UserInfo.isTrainingFlg()) {
                        otherSubLinks.push(this.simulationLink);
                    }

                    // マニュアルダウンロード
                    otherSubLinks.push(this.manualLink);

                    // 防災リンク集
                    if (!this._lgwanFlg) {
                        otherSubLinks.push(this.dpLinkPageLink);
                    }
                    // よくある質問
                    // otherSubLinks.push(this.questionsLink);

                    // MEMO:デモ用にメニュー表示制御
                    /* if (otherSubLinks.length > 0) {
                        links.push({ label: this.otherSubLinksLabel, links: otherSubLinks });
                    } */
                }
                return links;
            }
        });
});
